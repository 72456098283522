@font-face {
    font-family: "MYRIADPRO-REGULAR";
    src: url("/MYRIADPRO-REGULAR.OTF");
}
@font-face {
    font-family: "MYRIADPRO-BOLD";
    src: url("/MYRIADPRO-BOLD.OTF");
}

// Variables
@import 'variables';
@import 'variables2';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import 'bootswatch';
